import React from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";

import svg_directions from "./../assets/svg/undraw-directions_x53j.svg";
import svg_fish_bowl from "./../assets/svg/undraw-fish_bowl_uu88.svg";
import svg_personal_settings from "./../assets/svg/undraw-personal_settings_kihd.svg";
import svg_stability_ball from "./../assets/svg/undraw-stability_ball_b4ia.svg";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "center",
  },
  imageContainer: {
    margin: "0 auto",
    maxWidth: "200px",
    marginBottom: "30px",
    "& img": {
      width: "100%",
    },
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "AIによる予測分析の導入",
      body: "先進的な予測分析ツールを活用して、企業の市場動向を予測し、戦略的な意思決定を支援しています。データ駆動型アプローチで業績を最適化し、競争力を高める",
      image: svg_fish_bowl,
    },
    {
      title: "通信業界のクラウド変革",
      body: "通信業界におけるクラウド環境の構築では、スケーラビリティとセキュリティを最前線に置き、キャリア会社がデータとサービスを円滑に管理できるソリューションを提供している",
      image: svg_directions,
    },
    {
      title: "データウェアハウスのデータ分析",
      body: "大規模データウェアハウスからのデータ分析を通じて、企業は顧客行動の深い洞察を得ている。複雑なデータセットを分析し、ビジネスの成長を牽引する戦略的な情報を抽出することにより、意思決定プロセスを強化している",
      image: svg_stability_ball,
    },
    {
      title: "AIによるコンサルティング業界の変革",
      body: "シンプルな分析から洗練されたアドバイスまでを提供するAIツールを通じて、ビジネスの課題を初期段階で正確に特定し、必要に応じて専門のコンサルタントに迅速に繋ぐことで、クライアントのビジネス戦略と実行力を高めるサービスの開発に取り組んでいる",
      image: svg_personal_settings,
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Card raised={false}>
          <Grid container={true}>
            {items.map((item, index) => (
              <Grid
                item={true}
                xs={12}
                md={6}
                className={classes.gridItem}
                key={index}
              >
                <Box p={6}>
                  <div className={classes.imageContainer}>
                    <AspectRatio ratio={4 / 3}>
                      <img src={item.image} alt={item.title} />
                    </AspectRatio>
                  </div>
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">{item.body}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
