import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import Chat from "./../components/Chat";
import { ThemeProvider } from "./../util/theme";
import logoBlack from "./../assets/logo/logo-black.svg";
import logoWhite from "./../assets/logo/logo-white.svg";

function App(props) {
  return (
    <ThemeProvider>
      <>
        <Chat />
        <Router>
          <>
            <Navbar
              color="default"
              logo={logoWhite}
              logoInverted={logoBlack}
            />

            <Switch>
              <Route exact path="/" component={IndexPage} />

              <Route exact path="/about" component={AboutPage} />

              <Route exact path="/faq" component={FaqPage} />

              <Route exact path="/contact" component={ContactPage} />

              <Route exact path="/legal/:section" component={LegalPage} />

              <Route component={NotFoundPage} />
            </Switch>

            <Footer
              bgColor="default"
              size="medium"
              bgImage=""
              bgImageOpacity={1}
              description="クラウドとAIの未来をリードする"
              copyright={`© ${new Date().getFullYear()} Company`}
              logo={logoWhite}
              logoInverted={logoBlack}
              sticky={true}
            />
          </>
        </Router>
      </>
    </ThemeProvider>
  );
}

export default App;
