import React from "react";
import Meta from "./../components/Meta";
import FeaturesSection2 from "./../components/FeaturesSection2";
import svg_about from "./../assets/svg/undraw-chatting_2yvo.svg";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <FeaturesSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        image={svg_about}
      />
    </>
  );
}

export default AboutPage;
