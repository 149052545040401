import React from "react";
import LongContent from "./LongContent";

function LegalPrivacy(props) {
  return (
    <LongContent>
      <h1>プライバシーポリシー</h1>
      <p>株式会社Ｎｅｏｒｉｇｉｎ（以下、「当社」といいます。）は、当社のホームページをご利用いただくユーザー（以下、「ユーザー」といいます。）のプライバシーを尊重し、個人情報の保護に努めています。</p>
      <h2>1. 収集する情報</h2>
      <div>当社は、ユーザーが当社のホームページを利用する際に、以下の情報を収集することがあります：</div>
      <ul>
        <li>名前、メールアドレスなどの連絡先情報</li>
        <li>IPアドレス、ブラウザの種類、アクセス時間などの技術的な情報</li>
        <li>サービス利用時のユーザーの行動情報</li>
      </ul>
      <h2>2. 情報の利用目的</h2>
      <div>収集した情報は以下の目的で使用されます：</div>
      <ul>
        <li>当社サービスの提供・改善</li>
        <li>ユーザーサポート</li>
        <li>新サービス、機能の案内</li>
        <li>統計的な分析によるマーケティング戦略の策定</li>
      </ul>
      <h2>3. 情報の共有と開示</h2>
      <div>当社は、以下の場合を除き、収集した個人情報を第三者と共有または開示しません：</div>
      <ul>
        <li>ユーザーの同意がある場合</li>
        <li>法律に基づく要求がある場合</li>
        <li>ユーザーの権利や安全を守るために必要な場合</li>
      </ul>
      <h2> 4. 情報の保護</h2>
      <div>当社は、収集した情報の安全性を確保するために適切な物理的、技術的、組織的対策を講じています。</div>
      <h2> 5. クッキーの使用</h2>
      <div>当社のホームページは、ユーザー体験を向上させるためにクッキーを使用することがあります。ユーザーは、ブラウザの設定を変更することにより、クッキーの使用を拒否することができますが、その場合、ホームページの一部の機能が利用できなくなることがあります。</div>
      <h2> 6. プライバシーポリシーの変更</h2>

      当社は、必要に応じて本プライバシーポリシーを更新することがあります。変更がある場合は、当社ホームページ上で通知します。
      <h2> 7. お問い合わせ</h2>
      <p>
        <div>本ポリシーに関するご質問やご不明点がございましたら、当社までお問い合わせください。</div>
        <div>株式会社Ｎｅｏｒｉｇｉｎ</div>
        <div>〒2310062　神奈川県横浜市中区桜木町１丁目１０１番地１クロスゲート７階</div>
      </p>
      <p>作成日：2022年9月</p>


    </LongContent>
  );
}

export default LegalPrivacy;
