import React from "react";
import LongContent from "./LongContent";

function LegalTerms(props) {
  return (
    <LongContent>
      <h1>利用規約</h1>
      <div>本利用規約（以下、「本規約」といいます。）は、株式会社Ｎｅｏｒｉｇｉｎ（以下、「当社」といいます。）が提供するサービス及び関連する全てのサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。本サービスを利用する全ての利用者（以下、「ユーザー」といいます。）は、本規約に同意したものとみなされます。</div>
      <h2>1. 適用</h2>
      <div>本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。</div>
      <h2>2. 利用登録</h2>
      <ul>
        <li>ユーザーは、本サービスの利用にあたり、真実かつ正確な情報を提供して登録手続きを行うものとします。</li>
        <li>当社は、利用登録の申請者に対して、登録申請に基づく本サービスの利用を承認することができるものとします。ただし、以下のいずれかに該当する場合、当社は利用登録の申請を承認しないことがあります。</li>
        <ul>
          <li>申請に際して虚偽の事項を申告した場合</li>
          <li>本規約に違反したことがある者からの申請である場合</li>
          <li>その他、当社が利用登録を相当でないと判断した場合</li>
        </ul>
      </ul>
      <h2>3. 禁止事項</h2>
      <p>ユーザーは、本サービスの利用にあたり、以下の行為をしてはならないものとします。</p>
      <ul>
        <li>法令または公序良俗に違反する行為</li>
        <li>犯罪行為に関連する行為</li>
        <li>当社、本サービスの他のユーザー、または第三者の知的財産権、肖像権、プライバシー権等を侵害する行為</li>
        <li>本サービスの運営を妨害する行為</li>
        <li>不正アクセスを行う行為</li>
        <li>その他、当社が不適切と判断する行為</li>
      </ul>
      <h2>4. 本サービスの提供の停止等</h2>
      <p>当社は、以下のいずれかに該当する場合、ユーザーに事前に通知することなく、本サービスの提供を停止または中断することができるものとします。</p>
      <ul>
        <li>本サービスにかかるコンピュータシステムの保守を定期的または緊急に行う場合</li>
        <li>地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合</li>
        <li>コンピュータまたは通信回線等が事故により停止した場合</li>
      </ul>
    </LongContent>
  );
}

export default LegalTerms;
